import React, { FC } from 'react';
import { IProductFeaturesProps } from './model';

import './ProductFeatures.scss';
import './ProductFeaturesExtends.scss';
import './ProductFeaturesRTL.scss';

const ProductFeatures: FC<IProductFeaturesProps> = ({ productFeatures }) => {
  const {
    properties: { productTextTitle, productFeaturesText },
  } = productFeatures[0];

  return (
    <div className="product-features">
      <h2 className="product-features__title">{productTextTitle}</h2>
      <ul className="product-features__ul">
        {productFeaturesText.map(({ value }) => (
          <li key={value} className="product-features__li">
            {value}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductFeatures;
