import React, { FC } from 'react';
import classNames from 'classnames';

import { IProductTitleProps } from './model';

import './ProductTitle.scss';

const ProductTitle: FC<IProductTitleProps> = ({ title, amountCondoms, classes }) => {
  const classesTitle = classNames('product-title-wrapper', {
    [`${classes}`]: classes,
  });

  return (
    <div className={classesTitle}>
      <h1 className="product-title">{title}</h1>
      <div className="product-amount-condoms">{amountCondoms}</div>
    </div>
  );
};

export default ProductTitle;
