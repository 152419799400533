import React, { FC } from 'react';
import Button from 'common/Button';
import classNames from 'classnames';

import RelatedProductCard from 'components/RelatedProductCard';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';
import RelatedProductsTitle from './RelatedProductsTitle';

import { IRelatedProductsProps } from './models';
import './RelatedProducts.scss';
import './RelatedProductsExtends.scss';
import './RelatedProductsRTL.scss';

const RelatedProducts: FC<IRelatedProductsProps> = ({
  mainProduct,
  relatedProducts,
  relatedProductsCardCtaButton,
  relatedProductsCtaButtonShow,
  relatedProductsCentered,
  relatedProductsTitle,
  relatedProductsCardCTAButtonAriaLabel,
  relatedProductsCtaButton,
  relatedProductsDescription,
  relatedProductsBadge,
  sectionTheme,
}) => {
  return (
    <div
      className={classNames('related-product', {
        [`${sectionTheme}`]: sectionTheme,
        'related-product__without-button': relatedProductsCtaButtonShow !== '1',
      })}
    >
      {relatedProductsBadge ? (
        <div className="related-product__badge">
          <div className="related-product__badge--text">{relatedProductsBadge}</div>
        </div>
      ) : null}

      <RelatedProductsTitle title={relatedProductsTitle} />

      {relatedProductsDescription ? (
        <DangerouslySetInnerHtml
          html={relatedProductsDescription}
          className="related-product__description"
        />
      ) : null}

      {mainProduct && mainProduct?.nodes?.length > 0 ? (
        <div className="related-product__main-product">
          <RelatedProductCard
            key={mainProduct.nodes[0].url}
            productCard={mainProduct.nodes[0]}
            relatedProductsCardCtaButton={relatedProductsCardCtaButton}
          />
        </div>
      ) : null}

      <div
        className={classNames('related-product__cards-wrapper', {
          'related-product__cards-wrapper--centered': relatedProductsCentered,
        })}
      >
        {relatedProducts.nodes && Array.isArray(relatedProducts.nodes)
          ? relatedProducts.nodes.map((item) => (
              <RelatedProductCard
                key={item.url}
                productCard={item}
                relatedProductsCardCtaButton={relatedProductsCardCtaButton}
              />
            ))
          : null}
      </div>

      {relatedProductsCtaButtonShow === '1' ? (
        <div className="related-product__button">
          <Button
            classes="button--outline-dark"
            link={relatedProductsCtaButton?.[0].url}
            ariaLabel={relatedProductsCardCTAButtonAriaLabel || relatedProductsCtaButton?.[0].name}
          >
            {relatedProductsCtaButton?.[0].name}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default RelatedProducts;
