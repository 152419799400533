import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import LINKS from 'shared/static/links';
import scrollToElement from 'gatsby-theme-husky/src/utils/scrollToElement';
import { Helmet } from 'react-helmet';
import TIMES from 'shared/static/timeOuts';

import { IBazaarvoiceStarsComponentProps } from './models';
import './BazaarvoiceStars.scss';

const BazaarvoiceStars: FC<IBazaarvoiceStarsComponentProps> = ({
  ean,
  dataBvShow = 'rating_summary',
  reviewWrapperOffSetTop,
}) => {
  const [showBazaarvoice, setShowBazaarvoice] = useState<boolean>(false);
  const [userInteraction, setUserInteraction] = useState<boolean>(false);

  const openBazaarWindow = () => {
    const starsWrapper: HTMLButtonElement | null = document.querySelector('#ratings-summary');
    const ratingStarsElements: NodeListOf<Element> | null = document.querySelectorAll(
      '.bv_modal_inner_content > *'
    );

    starsWrapper?.addEventListener('click', () =>
      scrollToElement({ elementTop: reviewWrapperOffSetTop })
    );
    ratingStarsElements?.forEach((star) =>
      star.addEventListener('click', () => {
        scrollToElement({ elementTop: reviewWrapperOffSetTop });
      })
    );
  };

  useEffect(() => {
    userInteraction &&
      setTimeout(() => {
        setShowBazaarvoice(true);
      }, TIMES.DELAY_TIME);
  }, [userInteraction]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 10) setUserInteraction(true);
    });
    window.addEventListener('click', () => {
      setUserInteraction(true);
    });
  }, []);

  return (
    <>
      {userInteraction && (
        <Helmet>
          <script key="bazaarvoice" type="text/javascript" async src={LINKS.URL_BAZAARVOICE} />
        </Helmet>
      )}
      <div role="button" tabIndex={0}>
        <div
          className={classNames(`bazaarvoice-stars-animation`, {
            [`active`]: showBazaarvoice,
          })}
        >
          <div
            data-bv-show={dataBvShow}
            data-bv-product-id={showBazaarvoice ? ean : null}
            data-bv-ready="true"
            onMouseEnter={() => openBazaarWindow()}
          />
        </div>
      </div>
    </>
  );
};

export default BazaarvoiceStars;
