import React, { FC, useCallback, useState, useRef, useMemo } from 'react';
import classnames from 'classnames';

import Button from 'gatsby-theme-husky/src/common/Button';
import IconButton from 'gatsby-theme-husky/src/common/IconButton';
import useClickOutside from 'hooks/useClickOutside';
import { DangerouslySetInnerHtml } from 'layout';

import { IBuyNowDropdownProps } from './model';

import './BuyNowDropdown.scss';

const BuyNowDropdown: FC<IBuyNowDropdownProps> = ({ buyNow, activeVariant }) => {
  const [isOpened, setIsOpened] = useState(false);
  const buyNowHolderRef = useRef(null);

  const toggleOpen = useCallback(() => setIsOpened((prev) => !prev), []);
  const closeDropdownHandler = useCallback(() => {
    if (isOpened) setIsOpened(false);
  }, [isOpened]);

  useClickOutside(buyNowHolderRef, closeDropdownHandler);

  const activeDropDown = useMemo(
    () =>
      (buyNow?.length &&
        activeVariant &&
        buyNow[0].properties.variant &&
        buyNow.filter((el) => el?.properties?.variant === activeVariant)) ||
      buyNow,
    [activeVariant, buyNow]
  );

  const {
    triggerButtonTitle,
    triggerButtonAriaLabel,
    shops,
    dropdownTitle,
    closeButtonTitle,
    closeButtonAriaLabel,
  } = activeDropDown[0]?.properties || {};

  if (!activeDropDown.length) {
    return null;
  }

  return (
    <div className="buy-now" ref={buyNowHolderRef}>
      <Button
        ariaLabel={triggerButtonAriaLabel}
        handler={toggleOpen}
        btnColorVariant="dark"
        className="product-buttons-styles"
        classes={classnames('buy-now__dropdown-trigger-btn', {
          'buy-now__dropdown-trigger-btn--opened': isOpened,
        })}
      >
        {triggerButtonTitle}
        <span className="icon-arrow-down" />
      </Button>

      {isOpened ? (
        <div className="buy-now__dropdown">
          <DangerouslySetInnerHtml html={dropdownTitle} className="buy-now__dropdown-title" />
          <div className="buy-now__shops">
            {shops?.map(
              ({
                properties: {
                  iconButtonAriaLabel,
                  iconButtonUrl,
                  iconButtonImage,
                  iconButtonImageAlt,
                },
              }) => (
                <IconButton
                  key={iconButtonUrl?.[0].url}
                  link={iconButtonUrl?.[0].url}
                  ariaLabel={iconButtonAriaLabel}
                  iconButtonImage={iconButtonImage}
                  iconButtonImageAlt={iconButtonImageAlt}
                />
              )
            )}
          </div>

          <Button
            ariaLabel={closeButtonAriaLabel}
            handler={toggleOpen}
            btnColorVariant="dark"
            className="buy-now__dropdown--close-btn"
          >
            {closeButtonTitle}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default BuyNowDropdown;
