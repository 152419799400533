import React, { FC } from 'react';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';

import { IProductDescriptionProps } from './model';

import './ProductDescription.scss';
import './ProductDescriptionExtends.scss';

const ProductDescription: FC<IProductDescriptionProps> = ({ productDescriptionText }) => {
  const {
    properties: { descriptionText, descriptionTitle },
  } = productDescriptionText[0];

  return (
    <div className="product-description">
      <h2 className="product-description__title">{descriptionTitle}</h2>
      <DangerouslySetInnerHtml html={descriptionText} />
    </div>
  );
};

export default ProductDescription;
