import React, { FC, useState, useLayoutEffect, useRef } from 'react';

import { IBazaarvoiceReviewsComponentProps } from './models';
import './BazaarvoiceReviews.scss';

const BazaarvoiceReviews: FC<IBazaarvoiceReviewsComponentProps> = ({
  ean,
  reviewsTitle,
  setReviewWrapperOffSetTop,
}) => {
  const [productId, setProductId] = useState<string>('0');
  const reviewWrapperEl = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    reviewWrapperEl?.current ? setReviewWrapperOffSetTop(reviewWrapperEl.current?.offsetTop) : null;
    setProductId(ean);
  }, []);

  return (
    <div className="reviews" ref={reviewWrapperEl}>
      <p className="reviews__title">{reviewsTitle}</p>
      <div data-bv-show="reviews" data-bv-product-id={productId} />
    </div>
  );
};

export default BazaarvoiceReviews;
