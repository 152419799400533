import React, { FC } from 'react';

import { IProductReview } from './model';

import './ProductReview.scss';

const ProductReview: FC<IProductReview> = ({ sku }) => (
  <div className="yotpo yotpo-shoppers-say" data-product-id={sku} />
);

export default ProductReview;
