import React, { FC } from 'react';
import classNames from 'classnames';
import { IReviewStars } from './model';
import './ReviewStars.scss';
import './ReviewStarsRTL.scss';

const ReviewStars: FC<IReviewStars> = ({ sku, className, showReview = true }) => {
  const reviewsClasses = classNames('yotpo bottomLine review-stars', {
    [`${className}`]: className,
    [`with-out-review-string`]: !showReview,
  });

  return (
    <div
      className={reviewsClasses}
      data-appkey={process.env.GATSBY_YOTPO_API_KEY || 'LmuPJCZBRsu9YvqdPtLdxTXTCR6qJRbiPnCWTRqf'}
      data-product-id={sku}
    />
  );
};

export default ReviewStars;
