import { ScrollToParams } from './model';

const scrollToElement = ({ elementTop, behavior = 'smooth' }: ScrollToParams) => {
  window.scroll({
    top: elementTop,
    behavior,
  });
};

export default scrollToElement;
