import React, { FC } from 'react';
import { Link, graphql } from 'gatsby';
import GatsbyImage from 'common/GatsbyImage';

import { IIconTextButtonProps } from './model';
import './IconTextButton.scss';

const LinkWrapper: FC<Pick<IIconTextButtonProps, 'ariaLabel' | 'link'>> = ({
  ariaLabel,
  link,
  children,
}) => {
  const isInternal = /^\/(?!\/)/.test(link);

  return isInternal ? (
    <Link
      rel="preload"
      className="text-icon-button button button--dark"
      aria-label={ariaLabel}
      to={link}
      data-testid="text-icon-button"
    >
      {children}
    </Link>
  ) : (
    <a
      className="text-icon-button button button--dark"
      aria-label={ariaLabel}
      href={link}
      rel="noopener noreferrer"
      target="_blank"
      data-testid="text-icon-button"
    >
      {children}
    </a>
  );
};

const IconTextButton: FC<IIconTextButtonProps> = ({
  iconTextButtonTitle,
  ariaLabel,
  link,
  iconButtonImage,
  iconButtonImageAlt,
}) => (
  <LinkWrapper ariaLabel={ariaLabel} link={link}>
    <div className="text-icon-button__content">
      <p className="text-icon-button__title">{iconTextButtonTitle}</p>
      <div className="text-icon-button__image-wrapper">
        {iconButtonImage?.childImageSharp ? (
          <GatsbyImage
            fluid={iconButtonImage?.childImageSharp.fluid}
            alt={iconButtonImageAlt}
            objectFit="contain"
          />
        ) : null}
      </div>
    </div>
  </LinkWrapper>
);

export default IconTextButton;

export const query = graphql`
  fragment FragmentIconTextButton on ProductButtonsTypeProperties {
    iconTextButtonTitle
    iconButtonImage {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    iconButtonImageAlt
    iconButtonAriaLabel
    iconButtonUrl {
      url
    }
  }
`;
