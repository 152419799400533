import React, { FC } from 'react';
import { Link, graphql } from 'gatsby';
import GatsbyImage from 'common/GatsbyImage';
import { IIconButtonProps } from './model';
import './IconButton.scss';

const IconButton: FC<IIconButtonProps> = ({
  ariaLabel,
  link,
  iconButtonImage,
  iconButtonImageAlt,
}) => {
  const isInternal = /^\/(?!\/)/.test(link);

  return (
    <>
      {isInternal ? (
        <Link
          rel="preload"
          className="icon-button"
          aria-label={ariaLabel}
          to={link}
          data-testid="icon-button"
        >
          <div className="icon-button__image-wrapper">
            {iconButtonImage?.childImageSharp ? (
              <GatsbyImage
                fluid={iconButtonImage?.childImageSharp.fluid}
                alt={iconButtonImageAlt}
              />
            ) : null}
          </div>
        </Link>
      ) : (
        <a
          className="icon-button"
          aria-label={ariaLabel}
          href={link}
          rel="noopener noreferrer"
          target="_blank"
          data-testid="icon-button"
        >
          <div className="icon-button__image-wrapper">
            {iconButtonImage?.childImageSharp ? (
              <GatsbyImage
                fluid={iconButtonImage?.childImageSharp.fluid}
                alt={iconButtonImageAlt}
              />
            ) : null}
          </div>
        </a>
      )}
    </>
  );
};

export default IconButton;

export const query = graphql`
  fragment FragmentIconButton on ProductButtonsTypeProperties {
    iconButtonImage {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    iconButtonImageAlt
    iconButtonAriaLabel
    iconButtonUrl {
      url
    }
  }
`;
