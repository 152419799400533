import React, { FC, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Thumbs } from 'swiper';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';

import classNames from 'classnames';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

import './ProductCarousel.scss';
import './ProductCarouselExtends.scss';
import 'gatsby-theme-husky/src/components/ProductCarousel/ProductCarouselRTL.scss';

import { ICarouselProps } from 'gatsby-theme-husky/src/components/ProductCarousel/model';

SwiperCore.use([Navigation, Thumbs]);

const ProductCarousel: FC<ICarouselProps> = ({ isMobile, images, isFirstViewport, classes }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const classesCarousel = classNames(`product-carousel`, {
    [`${classes}`]: classes,
  });

  return (
    <div className={classesCarousel}>
      <Swiper
        className="main"
        tag="section"
        wrapperTag="ul"
        spaceBetween={12}
        slidesPerView={1}
        thumbs={{ swiper: thumbsSwiper }}
      >
        {images && images.length >= 1
          ? images.map(({ mobileImage, desktopImage, properties: { imageAltLabel } }) => {
              const image = isMobile
                ? mobileImage?.childImageSharp.fluid
                : desktopImage?.childImageSharp.fluid;

              return image ? (
                <SwiperSlide tag="li" key={`${imageAltLabel}${image.src}`}>
                  <GatsbyImage fluid={image} alt={imageAltLabel} isLazyLoading={isFirstViewport} />
                </SwiperSlide>
              ) : null;
            })
          : null}
      </Swiper>
      {images && images.length !== 1 ? (
        <div className="product-carousel-container">
          <Swiper
            className="thumb"
            tag="section"
            wrapperTag="ul"
            spaceBetween={10}
            slidesPerView={2}
            onSwiper={setThumbsSwiper}
            navigation
            slidesPerGroup={2}
          >
            {images && images.length > 1
              ? images.map(({ mobileImage, desktopImage, properties: { imageAltLabel } }) => {
                  const image = isMobile
                    ? mobileImage?.childImageSharp.fluid
                    : desktopImage?.childImageSharp.fluid;

                  return image ? (
                    <SwiperSlide tag="li" key={`${image.src}${imageAltLabel}`}>
                      <GatsbyImage
                        fluid={image}
                        alt={imageAltLabel}
                        isLazyLoading={isFirstViewport}
                      />
                    </SwiperSlide>
                  ) : null;
                })
              : null}
          </Swiper>
        </div>
      ) : null}
    </div>
  );
};

export default ProductCarousel;
