import React, { FC } from 'react';
import { IProductAdvantagesProps } from './model';
import './ProductAdvantages.scss';
import './ProductAdvantagesRTL.scss';

const ProductAdvantages: FC<IProductAdvantagesProps> = ({ productAdvantages }) => (
  <div className="product-advantages">
    {productAdvantages
      ? productAdvantages.map(({ properties: { productAdvantage, productAdvantageComment } }) => (
          <div key={productAdvantage} className="product-advantages__wrapper">
            <div className="product-advantages__left-block" />
            <div className="product-advantages__content">
              <span className="product-advantages__text">{productAdvantage}</span>
              {productAdvantageComment ? (
                <span className="product-advantages__comment">{productAdvantageComment}</span>
              ) : null}
            </div>
          </div>
        ))
      : null}
  </div>
);

export default ProductAdvantages;
